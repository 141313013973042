import type {
  AlgoliaSearchResponse,
  AlgoliaFrameHit,
  AlgoliaLensHit,
  AlgoliaCoatingHit,
  AlgoliaPrismHit,
  AlgoliaTintHit,
  AlgoliaOtherHit,
} from '@/types/algoliaSearch'

const normalizationFrameHit = (hit: AlgoliaFrameHit) => ({
  name: hit?.Name,
  sku: hit?.sku,
  family: hit?.family,
  price: hit?.Price,
  frame_color: hit?.Main_Color_data?.values?.label?.data,
  rgb_color_code: hit?.Main_Color_data?.values?.hex_html_?.data,
  image_url_front_view: hit?.Product_Eyeglass_Front_image_url,
  image_url_angle_view: hit?.Product_Eyeglass_Angle_image_URL,
  image_url_side_view: hit?.Product_Eyeglass_Side_image_URL,
  image_url_tinted_view: hit?.Product_Sunglass_Front_image_URL,
  frame_size: hit?.Frame___Size_data?.values?.label?.data,
  frame_shape: hit?.Frame___Shape_data?.values?.label?.data,
  bridge: hit?.FrameBridgeLength,
  temple: hit?.FrameTemplelength,
  weight: hit?.Frame___weight,
  frame_width: hit?.FrameWidth,
  lens_width: hit?.FrameLensWidth,
  material_code: hit?.Frame___Material_data?.values?.label?.data,
  material_description: hit?.Frame___Material_data?.values?.description?.data,
  decentration_available: hit?.Frame___Lens_support?.includes('decentration')
    ? 'Yes'
    : 'No',
  progressive_available: hit?.Frame___Prescription_types?.includes(
    'progressive',
  )
    ? 'Yes'
    : 'No',
  // hit?.frame_type_code all lowercase, ex: nonrxsunglasses.
  frame_type_code: hit?.frame_type_code,
  rim_type_code: hit?.Frame___Rim_type_data?.values?.label?.data,
  lens_height: hit?.FrameLensHeight,
  features: Object.values(Object.assign(hit?.Frame___Features_data ?? {},hit?.Safety_Features_data ?? {})).map(
    (e) => e.values.description?.data || e.values.label.data,
  ),
  description:
    hit?.Long_Description_Default?.replace(/\n/g, '<br />') ||
    hit?.B2C_Frame_Description,
  badges: hit?.Badges,
})

const normalizationLensHit = (hit: AlgoliaLensHit) => ({
  name: hit?.Name,
  sku: hit?.sku,
  family: hit?.family,
  lendIndex: hit?.Lens_Index,
  Lens___Color: hit?.Lens___Color,
  Photochromic_type: hit?.Photochromic_type,
  Sunglasses_Type: hit?.Sunglasses_Type,
  lensPrescriptionType: hit?.LensPrescriptionType_data?.values?.label?.data,
  progressiveType: hit?.Progressive_types?.[0],
})

const normalizationCoatingHit = (hit: AlgoliaCoatingHit) => ({
  name: hit?.Name,
  description: hit.Description,
  sku: hit?.sku,
  family: hit?.family,
  lensCoatingData: hit?.coating_resistant_type_data,
  coatingCode: hit?.CoatingCode,
  label: hit?.Description ?? '',
  price: hit?.Price ?? 0,
})

const normalizationPrismHit = (hit: AlgoliaPrismHit) => ({
  name: hit?.Name,
  sku: hit?.sku,
  family: hit?.family,
  label: hit?.Description ?? '',
  price: hit?.Price ?? 0,
})

const normalizationTintHit = (hit: AlgoliaTintHit) => ({
  name: hit?.Name,
  sku: hit?.sku,
  family: hit?.family,
  tintColor: hit?.Tint___Color,
  tintType: hit?.Tint___type,
  label: hit?.Tint___type_data?.values?.title?.data,
})

const normalizationOtherHit = (hit: AlgoliaOtherHit) => ({
  name: hit?.Name,
  sku: hit?.sku,
  family: hit?.family,
})

const normalizationFrameHits = (hits: AlgoliaFrameHit[]) => {
  if (!hits) return hits

  return hits?.map((item) => normalizationFrameHit(item))
}

const normalizationLensHits = (hits: AlgoliaLensHit[]) => {
  if (!hits) return hits

  return hits?.map((item) => normalizationLensHit(item))
}

const normalizationFrameProductsFromSearchResponse = (
  res: AlgoliaSearchResponse,
) => ({
  ...res,
  hits: normalizationFrameHits(res?.hits),
})

export {
  normalizationFrameProductsFromSearchResponse,
  normalizationFrameHits,
  normalizationFrameHit,
  normalizationLensHits,
  normalizationLensHit,
  normalizationCoatingHit,
  normalizationPrismHit,
  normalizationTintHit,
  normalizationOtherHit,
}
